// src/contexts/MyProvider.tsx
import React, { useState, ReactNode } from 'react';
import LanguageContext from './AppContext';
import constants from './Constants/constants';
import it from './Constants/locale/strings.it';
import en from './Constants/locale/strings.en';
import fr from './Constants/locale/strings.fr';
import LocaleStrings from './interfaces/LocaleStrings';
import useConfig from './useConfig';
import Config from './interfaces/Config';

interface AppContextProviderProps {
    children: ReactNode;
}

// Map language codes to locale strings
const localeStrings = {
    it: it,
    en: en,
    fr: fr,
};

const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
    // Detect the browser's language
    const browserLang = navigator.language.slice(0, 2);

    // Use the browser's language as the initial language, if it's one of the available languages
    const initialLang = localeStrings.hasOwnProperty(browserLang) ? browserLang : "en";

    const [lang, setLang] = useState<string>(initialLang);
    const [strings, setLocale] = useState<LocaleStrings>(localeStrings[initialLang as keyof typeof localeStrings]);

    const config : Config = useConfig();

    // Update setLang to also update locale strings
    const changeLang = (newLang: string) => {
        setLang(newLang);
        setLocale(localeStrings[newLang as keyof typeof localeStrings]);
    };

    // Prepare the context value
    const contextValue = { lang, setLang: changeLang, strings, constants, config };


    return (
        <LanguageContext.Provider value={contextValue}>
            {children}
        </LanguageContext.Provider>
    );
};

export default AppContextProvider;
