import React from 'react';
import './footer.scss';
import constants from '../../Constants/constants';
import it from '../../Constants/locale/strings.it';
const Footer: React.FC = () => {
    return (
        <div className="container">
            <footer>
                <strong>{it.title}</strong>
                <address>{constants.address}</address>
                <p>{constants.phone} - {constants.email}</p>
                <p>CIN: {constants.ucin}</p>
                <p><a href={"https://" + constants.webaddress}>{constants.webaddress}</a></p>
            </footer>
        </div>
    );
};

export default Footer;