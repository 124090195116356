const constants = {
    slideshow: [
        "/slideshow/01.jpg",
        "/slideshow/02.jpg",
        "/slideshow/03.jpg",
        "/slideshow/04.jpg",
        "/slideshow/05.jpg"
    ],

    carousel: [
        {
            image: "/carousel/01.jpg",
            thumbnail: "/carousel/01_thumbnail.jpg"
        },
        {
            image: "/carousel/02.jpg",
            thumbnail: "/carousel/02_thumbnail.jpg"
        },
        {
            image: "/carousel/03.jpg",
            thumbnail: "/carousel/03_thumbnail.jpg"
        },
        {
            image: "/carousel/04.jpg",
            thumbnail: "/carousel/04_thumbnail.jpg"
        },
        {
            image: "/carousel/05.jpg",
            thumbnail: "/carousel/05_thumbnail.jpg"
        },
        {
            image: "/carousel/06.jpg",
            thumbnail: "/carousel/06_thumbnail.jpg"
        },
        {
            image: "/carousel/07.jpg",
            thumbnail: "/carousel/07_thumbnail.jpg"
        },
        {
            image: "/carousel/08.jpg",
            thumbnail: "/carousel/08_thumbnail.jpg"
        },
        {
            image: "/carousel/09.jpg",
            thumbnail: "/carousel/09_thumbnail.jpg"
        },
        {
            image: "/carousel/11.jpg",
            thumbnail: "/carousel/11_thumbnail.jpg"
        },
        {
            image: "/carousel/12.jpg",
            thumbnail: "/carousel/12_thumbnail.jpg"
        },
        {
            image: "/carousel/13.jpg",
            thumbnail: "/carousel/13_thumbnail.jpg"
        },
        {
            image: "/carousel/14.jpg",
            thumbnail: "/carousel/14_thumbnail.jpg"
        },
        {
            image: "/carousel/15.jpg",
            thumbnail: "/carousel/15_thumbnail.jpg"
        },
        {
            image: "/carousel/16.jpg",
            thumbnail: "/carousel/16_thumbnail.jpg"
        },
        {
            image: "/carousel/17.jpg",
            thumbnail: "/carousel/17_thumbnail.jpg"
        },
        {
            image: "/carousel/18.jpg",
            thumbnail: "/carousel/18_thumbnail.jpg"
        },
        {
            image: "/carousel/19.jpg",
            thumbnail: "/carousel/19_thumbnail.jpg"
        },
        {
            image: "/carousel/20.jpg",
            thumbnail: "/carousel/20_thumbnail.jpg"
        },
        {
            image: "/carousel/21.jpg",
            thumbnail: "/carousel/21_thumbnail.jpg"
        },
        {
            image: "/carousel/22.jpg",
            thumbnail: "/carousel/22_thumbnail.jpg"
        },
        {
            image: "/carousel/23.jpg",
            thumbnail: "/carousel/23_thumbnail.jpg"
        },
        {
            image: "/carousel/24.jpg",
            thumbnail: "/carousel/24_thumbnail.jpg"
        },
        {
            image: "/carousel/25.jpg",
            thumbnail: "/carousel/25_thumbnail.jpg"
        },
        {
            image: "/carousel/26.jpg",
            thumbnail: "/carousel/26_thumbnail.jpg"
        }
    ],

    webaddress: "www.casasaferula.it",
    email: "casasaferula@gmail.com",
    address: "Via Mar Egeo, 11, Quartu Sant'Elena, 09045 (CA), Italia",
    phone: "(+39)340-557-9667",
    ucin: "IT092051C2000P1980"

}

export default constants;