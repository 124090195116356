import React, { useContext, useState, FC } from 'react';
import './About.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import AppContext from '../../AppContext';

// Define the type for the images
interface Image {
  original: string;
  thumbnail: string;
}

const About: FC = () => {
  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);

  const { strings, constants, config } = useContext(AppContext) || {};

  const images: Image[] = constants.carousel.map((imgObj: { image: string, thumbnail: string }) => ({
    original: imgObj.image,
    thumbnail: imgObj.thumbnail
  }));

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,  // Numero di immagini per slide
    slidesToScroll: 4,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div id="about">
      <main>
        <h1>{strings.about.title}</h1>
        <div>
          <p>{strings.about.subtitle}</p>
        </div>

        <div className="grid-container">
          <div className='grid-column'>
            <section id="services" className="lists">
              <article>
                <h1>{strings.about.rooms}</h1>
                <ul>
                  {strings.about.roomsAmenities.map((amenity, index: number) => (
                    <li key={index}>{amenity}</li>
                  ))}
                </ul>
              </article>
            </section>
          </div>
          <div className='grid-column'>
            <section id="services" className="lists">
              <article>
                <h1>{strings.about.bathrooms}</h1>
                <ul>
                  {strings.about.bathroomsAmenities.map((amenity, index: number) => (
                    <li key={index}>{amenity}</li>
                  ))}
                </ul>
              </article>
            </section>
          </div>
          <div className='grid-column'>
            <section id="services" className="lists">
              <article>
                <h1>{strings.about.garden}</h1>
                <ul>
                  {strings.about.gardenAmenities.map((amenity, index: number) => (
                    <li key={index}>{amenity}</li>
                  ))}
                </ul>
              </article>
            </section>
          </div>
        </div>
      </main>

      <div className="slider">
        <Slider {...settings}>
          {images.map((img: Image, index: number) => (
            <div key={index} onClick={() => { setPhotoIndex(index); setLightboxOpen(true); }}>
              <img src={img.thumbnail} alt={`Immagine ${index}`} style={{ width: "100%" }} />
            </div>
          ))}
        </Slider>
        {lightboxOpen && (
          <Lightbox
            open={lightboxOpen}
            close={() => setLightboxOpen(false)}
            index={photoIndex}
            slides={images.map((img: Image) => ({ src: img.original, alt: `Immagine` }))}
          />
        )}
      </div>


    </div >
  );
};

export default About;