import React, { useContext, useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Icon } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import markerShadowPng from "leaflet/dist/images/marker-shadow.png";
import LanguageContext from '../../AppContext';

import constants from '../../Constants/constants';

import Footer from '../Footer/footer';
import './Reach.scss';
import { LatLngExpression } from 'leaflet';


const Reach: React.FC = () => {
    const { strings } = useContext(LanguageContext) || {};

    const position: LatLngExpression = [39.218209, 9.281828];
    const zoom = 14;

    const defaultIcon = new Icon({
        iconUrl: markerIconPng,
        shadowUrl: markerShadowPng,
        iconSize: [25, 41], // size of the icon
        iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
        popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
        shadowSize: [41, 41] // size of the shadow
    });


    return (
        <div className="container">
            <main id="reach">
                <h1>{strings?.reach.title}</h1>
                <h2>{strings?.title}</h2>
                <h3>{constants.address}</h3>
                <h4><i className="icon-mobile"></i>{constants.phone} - <i className="icon-mail-alt"></i><a href={"mailto:" + constants.email}>{constants.email}</a></h4>

                <MapContainer className="mapContainer" center={position} zoom={zoom} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position} icon={defaultIcon}>
                        <Popup >
                            Casa vacanze Sa Ferula
                        </Popup>
                    </Marker>
                </MapContainer>

                <div id="reachus">
                    <div className="lists">
                        <article>
                            <h1>{strings?.reach.byBusAirport}<i className="icon-bus"></i></h1>
                            <ul>
                                {strings?.reach.byBusAirportDesc.map((item: string | React.ReactNode, index: number) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </article>
                        <article>
                            <h1>{strings?.reach.byCarAirport}<i className="icon-bus"></i></h1>
                            <ul>
                                {strings?.reach.byCarAirportDesc.map((item: string | React.ReactNode, index: number) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </article>
                    </div>
                    <div className="lists">
                        <article>
                            <h1>{strings?.reach.byBusSeaport}<i className="icon-bus"></i></h1>
                            <ul>
                                {strings?.reach.byBusSeaportDesc.map((item: string | React.ReactNode, index: number) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </article>
                        <article>
                            <h1>{strings?.reach.byCarSeaport}<i className="icon-bus"></i></h1>
                            <ul>
                                {strings?.reach.byCarSeaportDesc.map((item: string | React.ReactNode, index: number) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </article>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Reach;