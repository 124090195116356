import { useContext } from 'react';
import './Header.scss';
import LanguageContext from '../AppContext';

interface HeaderProps {
    address?: string;
    phone?: string;
    email?: string;
}


const Header: React.FC<HeaderProps> = ({ address, phone, email }) => {
    const context = useContext(LanguageContext);
    const config = context?.config;

    const changeLanguage = (language: string) => {
        context?.setLang(language);
    };

    const getNotificationText = () => {
        if (config) {
            const language = context?.lang;
            if (language === 'it') {
                return config.notification.textIt;
            } else if (language === 'en') {
                return config.notification.textEn;
            } else if (language === 'fr') {
                return config.notification.textFr;
            }
        }
        return '';
    };


    return (
        <div className="header">
            <div>
                <div className="contacts">
                    <span><i className="icon-location"></i>{address}</span>
                    <span><i className="icon-mobile"></i>{phone}</span>
                    <span><i className="icon-mail-alt"></i>{email}</span>
                </div>
                <div className="lang">
                    <div onClick={() => changeLanguage('it')}>IT</div>
                    <div onClick={() => changeLanguage('en')}>EN</div>
                    <div onClick={() => changeLanguage('fr')}>FR</div>
                </div>
            </div>
            {config && config.notification.enabled && (
                <div className='notification-stripe'>
                    {getNotificationText()}
                </div>
            )}
        </div>

    );
};

export default Header;